  .input-container {
      position: relative;
      background-color: white;
      border-radius: 8px;
    }

  .input-border {
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .input-container label {
    position: absolute;
    top: 0;
    left: 10px;
    background-color: white;
    padding: 0 5px;
    transform: translateY(-50%);
    color: #666;
    font-size: 14px;
    border-radius: 4px;
  }
  
  .input-container input {
    width: 100%;
    padding: 10px 16px;
    font-size: 16px;
    border-radius: 8px;
  }
  
  .input-container input:focus {
    outline: none;
  }