.rectangle-blanc-ombre {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 4px 4px rgba(152, 152, 152, 0.25);
    padding: 20px 26px;
    text-align: left;
    margin: auto;
}

.rectangle-blanc-ombre p {
    margin: 0;
}

.largeur800 {
    width: 800px;
}

.auto-height {
    height: auto;
}

.centre-centre {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.centre {
    display: flex;
    justify-content: center;
}

.grid-20-auto-20 {
    display: grid;
    grid-template-rows: 20px auto 20px;
    row-gap: 20px;
}

.grid-2-auto {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.gap20 {
    gap: 20px;
}

.gap60 {
    gap: 60px;
}

.inline-block {
    display: inline-block;
}

.lien {
    color: #4B7BB4;
}

.lien-block {
    color: #4B7BB4;
    padding: 4px 10px;
    border: 1px solid transparent;
}

.lien-block:hover {
    cursor: pointer;
    padding: 4px 10px;
    background-color: rgba(75, 123, 180, 0.06);
    border: 1px solid #4B7BB4;
    border-radius: 6px;
}

.lien:hover {
    cursor: pointer;
    text-decoration: underline;
}

.full-button {
    background-color: #4B7BB4;
    padding: 10px 28px;
    border-radius: 10px;
    color: white;
}

.light-button {
    border: 1px solid #4B7BB4;
    color: #4B7BB4;
    padding: 10px 28px;
    border-radius: 10px;
    background-color: white;
}

.delete-button {
    border: 1px solid #FF0000;
    background-color: '#FFDFDF';
    color: #FF0000;
    padding: 10px 28px;
    border-radius: 10px;
    background-color: white;
    transition: background-color 0.5s ease; /* Animation sur 0.5 secondes */
}

.delete-button:hover {
    background-color: #FF0000; /* Couleur de fond rouge au survol */
    color: white; /* Change la couleur du texte au survol si nécessaire */
}

.light-button:hover, .full-button:hover, .delete-button:hover {
    cursor: pointer;
}

.hover:hover {
    cursor: pointer;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

nav p {
    margin-right: 10px;
}

.img-80 {
    height: 80px;
    width: 80px;
    object-fit: cover;
    border-radius: 6px;
}