.selecteur-container {
    position: relative;
}

.selecteur-container select {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px 16px;
    font-size: 16px;
    background-color: white;
  }
  
  .selecteur-container label {
    position: absolute;
    top: 0;
    left: 10px;
    background-color: white;
    padding: 0 5px;
    transform: translateY(-50%);
    color: #666;
    font-size: 14px;
  }
  
  .selecteur-container select:focus {
    outline: none;
  }