.carte-groupe {
    min-width: 300px;
    height: 160px;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.carte-groupe:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
}